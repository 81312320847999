<template>
  <div>
    <div class="container pb-4">
      <div class="columns is-align-items-flex-end">
        <div class="column">
          <slot name="filter"></slot>
        </div>
        <div class="column is-narrow">
          <DataTableColumnToggle
            :columns="columns"
            @toggleColumn="toggleColumn"
          />
        </div>
        <div class="column is-narrow">
          <DataTablePageSize />
        </div>
      </div>
    </div>
    <div class="data-table container" id="data-table">
      <div
        class="data-table__headers has-background-white"
        ref="header"
        :style="{ transform: 'translate3d(0,' + tableTop + ', 0)' }"
      >
        <DataTableHeaderCell
          @resizeColumn="resizeColumn"
          @sort="sort"
          v-for="(column, x) in visibleColumns"
          :key="x"
          v-bind="{ column, sortBy, sortOrder }"
        />
      </div>
      <div :style="{ marginTop: `${offsetHeight}px` }">
        <div class="data-table__rows" v-for="(row, x) in rows" :key="x">
          <div
            class="data-table__rows-cell"
            v-for="(column, key) in visibleColumns"
            :style="{ width: column.width + 'px' }"
            :key="key"
          >
            <component
              :is="column.component || 'DataTableBlockText'"
              :value="column.value(row, permissions)"
              :tooltip="
                column.getTooltip ? column.getTooltip(row, permissions) : null
              "
              :styles="
                column.getStyles ? column.getStyles(row, permissions) : null
              "
              :icon="column.getIcon ? column.getIcon(row, permissions) : null"
              :disabled="
                column.getDisabled
                  ? column.getDisabled(row, permissions)
                  : false
              "
              :on-click="
                column.onClick
                  ? function () {
                      column.onClick(row, permissions)
                    }
                  : null
              "
              :wait-key="
                column.getWaitKey ? column.getWaitKey(row, permissions) : null
              "
              v-if="!!column.value(row, permissions)"
            />
            <span v-else>-</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-2">
      <slot name="pagination"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DataTable',
  components: {
    DataTableHeaderCell: () => import('./DataTableHeaderCell'),
    DataTableColumnToggle: () => import('./DataTableColumnToggle'),
    DataTablePageSize: () => import('./DataTablePageSize'),
    DataTableBlockButton: () => import('./content/DataTableBlockButton'),
    DataTableBlockText: () => import('./content/DataTableBlockText')
  },
  data: () => ({
    tableTop: 0,
    offsetHeight: 0
  }),
  props: {
    rows: {
      type: Array,
      required: false,
      default: () => []
    },
    columns: {
      type: Array,
      required: true,
      default: () => []
    },
    sortBy: {
      type: null,
      required: true
    },
    sortOrder: {
      type: null,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    }
  },
  methods: {
    sort($event) {
      return this.$emit('sort', $event)
    },
    resizeColumn($event) {
      return this.$emit('resizeColumn', $event)
    },
    toggleColumn($event) {
      return this.$emit('toggleColumn', $event)
    },
    setPageSize($event) {
      return this.$emit('setPageSize', $event)
    },
    handleScroll() {
      const table = document.getElementById('data-table')
      const isScrolling = table.getBoundingClientRect().top <= 0
      if (isScrolling) {
        this.tableTop = Math.abs(table.getBoundingClientRect().top) + 'px'
      } else {
        this.tableTop = 0
      }
    },
    observeOffset() {
      const observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
          this.offsetHeight = entry.contentRect?.height || 0
        })
      })

      return observer.observe(this.$refs.header)
    }
  },
  mounted() {
    this.observeOffset()
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    visibleColumns() {
      return this.columns.filter(({ display }) => display)
    }
  }
}
</script>

<style lang="sass">
$cell-border: 1px solid #ccc
$cell-padding: .75rem
.data-table
  border-radius: 5px
  overflow-x: scroll
  border: $cell-border
.data-table__headers
  box-shadow: 0 1rem 1rem -1rem $white-ter
  position: absolute
  z-index: 1
.data-table__headers,
.data-table__rows
  display: flex
  min-width: max-content
  width: 100%
  border-bottom: $cell-border
.data-table__headers-cell,
.data-table__rows-cell
  display: flex
  align-items: center
  position: relative
  padding: $cell-padding
.data-table__rows-cell:not(:last-child),
.data-table__headers-cell:not(:last-child)
    border-right: $cell-border
</style>
