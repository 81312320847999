var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container pb-4"},[_c('div',{staticClass:"columns is-align-items-flex-end"},[_c('div',{staticClass:"column"},[_vm._t("filter")],2),_c('div',{staticClass:"column is-narrow"},[_c('DataTableColumnToggle',{attrs:{"columns":_vm.columns},on:{"toggleColumn":_vm.toggleColumn}})],1),_c('div',{staticClass:"column is-narrow"},[_c('DataTablePageSize')],1)])]),_c('div',{staticClass:"data-table container",attrs:{"id":"data-table"}},[_c('div',{ref:"header",staticClass:"data-table__headers has-background-white",style:({ transform: 'translate3d(0,' + _vm.tableTop + ', 0)' })},_vm._l((_vm.visibleColumns),function(column,x){return _c('DataTableHeaderCell',_vm._b({key:x,on:{"resizeColumn":_vm.resizeColumn,"sort":_vm.sort}},'DataTableHeaderCell',{ column, sortBy: _vm.sortBy, sortOrder: _vm.sortOrder },false))}),1),_c('div',{style:({ marginTop: `${_vm.offsetHeight}px` })},_vm._l((_vm.rows),function(row,x){return _c('div',{key:x,staticClass:"data-table__rows"},_vm._l((_vm.visibleColumns),function(column,key){return _c('div',{key:key,staticClass:"data-table__rows-cell",style:({ width: column.width + 'px' })},[(!!column.value(row, _vm.permissions))?_c(column.component || 'DataTableBlockText',{tag:"component",attrs:{"value":column.value(row, _vm.permissions),"tooltip":column.getTooltip ? column.getTooltip(row, _vm.permissions) : null,"styles":column.getStyles ? column.getStyles(row, _vm.permissions) : null,"icon":column.getIcon ? column.getIcon(row, _vm.permissions) : null,"disabled":column.getDisabled
                ? column.getDisabled(row, _vm.permissions)
                : false,"on-click":column.onClick
                ? function () {
                    column.onClick(row, _vm.permissions)
                  }
                : null,"wait-key":column.getWaitKey ? column.getWaitKey(row, _vm.permissions) : null}}):_c('span',[_vm._v("-")])],1)}),0)}),0)]),_c('div',{staticClass:"container pt-2"},[_vm._t("pagination")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }